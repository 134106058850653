<template>
  <div>
    <div id="site-wrapper" v-if="user" class="site-wrapper panel panel-ad-campaigns-start-new">
      <!-- #header start -->
      <NavBar />
      <!-- #header end -->
      <!-- #wrapper-content start -->
      <div id="wrapper-content" class="wrapper-content pt-0 pb-0">
        <div class="page-wrapper d-flex flex-wrap flex-xl-nowrap">
          <LeftSideBar />
          <div class="page-container">
            <div class="container-fluid">
              <div class="page-content-wrapper d-flex flex-column">

                <div id="section-accordion">

                  <DynamicModal v-if="businessAddress && isModalVisible" @close="closeModal"
                    v-bind:modal-size="'modal-lg'" v-bind:hide-close-btn="true">
                    <template v-slot:header>
                      Verify Telephone number
                    </template>

                    <template v-slot:body>
                      <ValidateBusinessAddressPhone v-bind:address="businessAddress"
                        v-bind:partner-id="$route.params.partnerId" v-bind:can-redirect="true"
                        v-bind:redirect-to="vRedirectTo" />
                    </template>

                    <template v-slot:footer>
                    </template>
                  </DynamicModal>

                </div>



                <div class="lh-1 mt-5">
                  <FooterBar />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- #wrapper-content end -->
    </div>


    <div v-else>
      <LoadingScreen />
    </div>

  </div>
</template>
<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";
  import NavBar from "../Layouts/NavBar";
  import LeftSideBar from "../Layouts/LeftSideBar";
  import FooterBar from "../Layouts/FooterBar";
  import LoadingScreen from "../Layouts/LoadingScreen";
  import DynamicModal from "../Common/Modal/DynamicModal";
  import ValidateBusinessAddressPhone from "../MyBusinessAddress/ValidateBusinessAddressPhone"
  export default {
    name: "VerifyBusinessAddressPhoneStepper",
    data: function () {
      return {
        isModalVisible: false,
        isLoading: false,
        vRedirectTo: {
          name: 'UploadBusinessImagesStepper',
          params: {
            partnerId: this.$route.params.partnerId
          }
        }
      }
    },
    components: {
      NavBar,
      LeftSideBar,
      FooterBar,
      LoadingScreen,
      DynamicModal,
      ValidateBusinessAddressPhone
    },
    computed: {
      ...mapGetters("businessAddress", ['businessAddress']),
      ...mapGetters(["errors", "isSuccess"]),
      ...mapGetters("auth", ["user"])
    },

    methods: {
      ...mapActions("auth"),
      ...mapActions("businessAddress", ['getBusinessAddress']),
      showModal() {
        this.isModalVisible = true;
      },
      closeModal() {
        this.isModalVisible = false;
      },
    },
    mounted() {

      this.getBusinessAddress(this.$route.params.partnerId).then(() => {
        if (this.businessAddress) {

          if (this.businessAddress.isTelephoneVerified) {
             this.$router.push(this.vRedirectTo).catch(() => {});
          } else {
            this.showModal();
          }
        }

      });
    },
  };
</script>